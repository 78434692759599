import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ConfigProvider} from 'antd';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <ConfigProvider theme={{
    components: {
      Pagination: {
            colorPrimary: '#182944',
            colorPrimaryHover: '#182944',
            colorPrimaryBorder: '#182944',
            controlOutline: '#182944'
          },
      Input: {
            colorPrimary: '#182944',
            colorPrimaryHover: '#182944',
            colorPrimaryActive: '#182944',
          },
      Button: {
            groupBorderColor: '#182944',
            defaultBorderColor: '#182944',
            defaultGhostBorderColor: '#182944',
            colorPrimary: '#182944',
            colorPrimaryHover: '#0958d9',
            colorLink: '#182944',
            colorLinkHover: '#0958d9',
            colorLinkActive: '#0958d9',
          },
    },
    token: {
            colorText: '#313141',
            fontFamily: 'Poppins'
        }}}>
    <App />
   </ConfigProvider>
  </React.StrictMode>
);


