export const NOTIFICATION_DETAILS = {
  success: {
    message: "Details Submitted!",
    description:
      "We've got your information. Our team will get in touch with you shortly!",
  },
  error: {
    message: "Something went wrong!",
    description: "Please try again later or create support ticket on our discord."
  },
};

export const FORM_URL = "https://api.nodes.sanr.network";
export const HOLDERS_URL = "https://api.nodes.sanr.network/api/v1/nodeHolders";
export const HOLDER_URL = "https://api.nodes.sanr.network/api/v1/nodeHolder";
export const DEPOSIT_URL = "https://api.nodes.sanr.network/api/v1/nodeHolder/depositData";
