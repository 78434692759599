import {useState, useCallback} from 'react';
import {Card, Form, Input, InputNumber} from 'antd';
import {styled} from 'styled-components';
import UseContactForm from '../../../utils/useContactForm';
import {NOTIFICATION_DETAILS} from '../../../utils/constants';
import {showNotification} from '../../../utils/showNotification';
import StoredAuth from '../../../utils/localStorage';
import {ButtonUpload} from '../../ButtonUpload/index';
import {ButtonAction} from '../../ButtonAction/index';


const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  margin-top: 32px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;
const Subtitle = styled.div`
  margin: 32px 0 24px;
  color: #6E778D;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 14px;
    }
    @media only screen and (max-width: 425px){
      font-size: 12px;
    }
`;

const FormWr = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0 20px;
  @media only screen and (max-width: 767px){
    padding: 0;
  }
`;

const UploadWr = styled.div`
  padding: 0 30px 20px;
`;


export function FormContent({isHolder, signedToken, isFileUpload=false}) {
  const [form] = Form.useForm();

  const userData = {...StoredAuth.get()};
  if (userData.validatorsCount === null) {
    userData.validatorsCount = 1;  //default value of validators in form
  }

  const isFormSent = Boolean(userData.email);

  const [formData, setFormData] = useState(userData);

  const handleSubmission = useCallback(
    (result, updatedData, values) => {
      if (result.error) {
        console.log('first error message', result.error);
        const errorMessage = `Message: ${result.error?.message}, timestamp: ${result.error?.timestamp}`
        showNotification("error", {message: `Data upload failed. ${errorMessage}`}, 10); // Show Notification
        form.setFieldsValue(values);
      } else {
        showNotification("success", NOTIFICATION_DETAILS.success); // Show Notification
        form.setFieldsValue(updatedData);
      }
    },
    [form]
  );

  const onSubmit = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields(); // Validate the form fields
    } catch (errorInfo) {
      return;
    }
    const result = await UseContactForm(values); // Submit the form data to the backend
    const {response} = {...result};
    const updatedData = {...StoredAuth.get(), ...response};
    StoredAuth.set(updatedData);
    setFormData({...updatedData});
    handleSubmission(result, updatedData, values); // Handle the submission after the API Call.
    console.log('first response', response);
    console.log('first result', result);
  }, [form, handleSubmission]);

  const fields = ["accessToken", "address", "nodeIP", "email", "validatorsCount", "discordUsername"];

  return (
    <Wr>
      <Title>Register your node</Title>
      {isFormSent && <Subtitle>The data in the form has been submitted. You can update the data.</Subtitle>}
      <FormWr>
        <Form // Ant Design's Form Component
          name="contact-us"
          layout="vertical"
          form={form}
          style={{
            marginTop: 20,
            paddingBottom: 10,
            paddingLeft: 30,
            paddingRight: 30,
          }}
          initialValues={{
            ...formData,
          }}
        >
          <Form.Item // Form Item (Token)
            name="accessToken"
            hidden
          >
            <Input disabled />
          </Form.Item>
          <Form.Item // Form Item (Address)
            label="The address from which you are going to make a deposit for staking"
            name="address"
            disabled
            required
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: "Please enter your Address!",
              },
            ]}
          >
            <Input disabled placeholder="Address" />
          </Form.Item>
          <Form.Item // Form Item (IP-address)
            label="IP-address of your node"
            name="nodeIP"
            required
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: "Please enter your IP-address of your node!",
              },
            ]}
          >
            <Input
            onChange={e => {
              setFormData({ ...formData, nodeIP: e.target.value }); 
            }}
            placeholder="IP-address" />
          </Form.Item>
          <Form.Item // Form Item (Email)
            label="Email"
            name="email"
            required
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: "Please enter your email!",
                type: "email",
              },
            ]}
          >
            <Input
            onChange={e => {
              setFormData({ ...formData, email: e.target.value }); 
            }}
            placeholder="Email" />
          </Form.Item>
          {isFileUpload && <Form.Item label="How many validators would you like to run"
            required
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: "Please enter Numbers of validators!",
                type: "number",
              },
            ]}>
            <Form.Item name="validatorsCount" noStyle>
              <InputNumber
              onChange={e => {
                setFormData({ ...formData, validatorsCount: e.target.value }); 
              }}
              min={1}
              max={10}/>
            </Form.Item>
          </Form.Item>}
          <Form.Item // Form Item (Discord nickname)
            label="Discord nickname"
            name="discordUsername"
            required
            tooltip="This is a required field"
            rules={[
              {
                required: true,
                message: "Please enter your Discord nickname!",
              },
            ]}
          >
            <Input
              onChange={e => {
                setFormData({ ...formData, discordUsername: e.target.value }); 
              }}
              placeholder="Discord nickname" />
          </Form.Item>
          <Form.Item // Form Item (Submit Button)
          >
            <UploadWr style={{maxWidth: '300px', margin: '0 auto'}}>
              <ButtonAction 
              disabled={Object.entries(formData).filter(f => fields.some(e => e === f[0])).some(f => f[1] === null)} 
              onClickPress={onSubmit} text={isFormSent ? 'Update' : 'Submit'}/>
            </UploadWr>
          </Form.Item>
        </Form>
      </FormWr>
      {isFileUpload && <FormWr>
        <Subtitle>The deposit file only needs to be uploaded<br/>if you install a node with a validator.</Subtitle>
        <UploadWr>
          <ButtonUpload signedToken={signedToken}/>
        </UploadWr>
      </FormWr>}
    </Wr>
  )
}