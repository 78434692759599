import { FORM_URL } from "./constants";
import {showNotification} from './showNotification';



const UseContactForm = (data) => {
const headers = {};
headers['Content-Type'] = 'application/json';
headers.Authorization = `Bearer ${data.accessToken}`;


const submitRequest = async (reqBody) => {
  let newError;
  try {
    const res = await fetch(FORM_URL + '/api/v1/nodeHolder', {
      method: "PUT",
      headers,
      body: JSON.stringify(reqBody),
    }).then(async (response) => {
      if (response.ok) {
        return response.json();
      }
        const errorResponse = await response.json();
        console.log(errorResponse);
        const errorMessage = `Message: ${errorResponse.message}, timestamp: ${errorResponse.timestamp}`;
        showNotification('error', {message: `Data upload failed. ${errorMessage}`}, 30);
        return newError = errorResponse
    })
    .catch((error) => {
      newError = error;
      console.log(error);
    });
    return { response: res, error: newError };
  } catch (e) {
    console.error(e);
    return { error: e };
  }
};

return submitRequest(data);
};

export default UseContactForm;
