import {styled} from 'styled-components';
import footerImg from '../../img/footer.png';


const Wr = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 320px;
  max-width: 1920px;
  display: flex;
  align-items: center;
  flex-flow: column;
  background: url(${footerImg});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
`;

const Title = styled.div`
  padding-top: 66px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #6E778D;
  @media only screen and (max-width: 767px){
    font-size: 18px;
  }
  @media only screen and (max-width: 425px){
    font-size: 16px;
    }
`;


export function Footer() {
  return (
    <Wr>
      {/*<Title>New submissions are processed manually and may take up to 24 hours to add to the table.</Title>*/}
    </Wr>
  )
}
