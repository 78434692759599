import {Space, Collapse, Typography} from 'antd';
import {styled} from 'styled-components';

const {Text} = Typography;

const Wr = styled(Space)`
  width: 90%;
  max-width: 1920px;
  margin-top: 76px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;

const CollapseWr = styled(Collapse)`
  & .ant-collapse-header-text {
    font-weight: bold;
  }
`;

const items = [
  {
    key: '1',
    label: 'What is a node in the Sanr Network blockchain?',
    children: <p>Sanr Network blockchain nodes are network stakeholders and their devices are authorized to keep track of the distributed ledger and serve as communication hubs for various network tasks.
    </p>,
  },
  {
    key: '2',
    label: 'How is a validator node different from a read-only node?',
    children: <p>A read-only node is authorized to keep track and sync data of the distributed ledger. On the other hand, a node with a validator is used to confirm the legality of each subsequent batch of network transactions, known as blocks.</p>,
  },
  {
    key: '3',
    label: 'How to set up a read-only node?',
    children: <p>Instructions on how to set up a read-only node can be found here: <br/><a href="https://github.com/santiment/sanr-pos-network#running-sanr-network-node-in-readonly-mode" target="_blank" rel="noreferrer">https://github.com/santiment/sanr-pos-network#running-sanr-network-node-in-readonly-mode</a></p>,
  },
  {
    key: '4',
    label: 'How to set up a node with a validator?',
    children: <p>Instructions on how to set up a node with validators can be found here: <br/><a href="https://github.com/santiment/sanr-pos-network#running-sanr-network-node-in-signer-mode" target="_blank" rel="noreferrer">https://github.com/santiment/sanr-pos-network#running-sanr-network-node-in-signer-mode</a></p>,
  },
  {
    key: '5',
    label: 'What to do if I encounter errors while setting up the node?',
    children: 
    <>
    <p>Please refer to the troubleshooting section: <br/><a href="https://github.com/santiment/sanr-pos-network#troubleshooting" target="_blank" rel="noreferrer">https://github.com/santiment/sanr-pos-network#troubleshooting</a></p>
    <p>If the mentioned sections do not help you resolve your issues, we recommend creating a support ticket in our Discord.</p>
    </>
  },
  {
    key: '6',
    label: 'How many validators can one node have?',
    children: <p>Each node can have up to 10 validators. The number of validators is specified during node configuration. To set up a validator, you need to stake SAN tokens by sending them to the address: 0xbFE5D25956F6D7CF212a24B628c7871A34c4465f. To set up one validator, you need to stake 2000 SAN tokens. Therefore, for 10 validators, you need to stake 20,000 SAN tokens.</p>,
  },
  {
    key: '7',
    label: 'Where can I buy tokens for staking?',
    children: 
    <>
    <p>You can buy tokens at  <br/><a href="https://app.uniswap.org/swap?outputCurrency=0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098" target="_blank" rel="noreferrer">https://app.uniswap.org/swap?outputCurrency=0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098</a></p>
    <p>We support a liquidity pool: <br/><a href="https://info.uniswap.org/#/pools/0x345bec0d86f156294e6602516284c19bd449be1e" target="_blank" rel="noreferrer">https://info.uniswap.org/#/pools/0x345bec0d86f156294e6602516284c19bd449be1e</a>. This ensures that you can sell your SAN tokens at any time if you want to stop supporting the network, turn off your validator, and unstake SAN tokens.</p>
    </>
  },
  {
    key: '8',
    label: 'How to withdraw my SAN from staking?',
    children: <p>To withdraw SAN from PoS staking, please leave us a request in Discord by opening a support ticket.</p>,
  },
  {
    key: '9',
    label: 'What are the rewards for node holders?',
    children: 
    <>
    <p>Node holders receive rewards in two ways: through network support points and SAN tokens.</p>
    <p>For read-only nodes, the only available reward is network support points, which equals 1 point per day. To receive 1 point, your node must correctly connected to the network for 24 hours.</p>
    <p>For nodes running validators, rewards are provided in two ways:<br/>
    1. Network support points: A node with a validator receives 2 points per day for supporting the network, plus an additional 0.2 point per validator. For example, if a node has 10 validators, the maximum reward is 4 points per day. Additionally, there are various bonus points that we will distribute periodically. For example, a bonus of 50 points if you become a properly functioning validator for a certain period of time.<br/>
    2. SAN tokens: Each validator node holder will have the opportunity to receive a 54% APY on their staking. Rewards will be distributed after significant epochs in the network's development, according to our roadmap. The roadmap will be published soon.</p>
    <p>It should be noted that the model of allocating support points may be subject to changes in the future.</p>
    </>
  },
  {
    key: '10',
    label: 'What are network support points?',
    children: <p>Network support points are special points that can be earned during the development of our network until the mainnet stage. These points will be considered during the distribution of sanr.network tokens within the community.</p>,
  },
  {
    key: '11',
    label: 'Can I change servers and still continue earning points?',
    children: <p>Yes, simply update your information in the form on nodes.sanr.network using the same address that you registered your initial node server with. You will continue to earn your points.</p>,
  },
  {
    key: '12',
    label: 'When will the mainnet be released?',
    children: <p>The mainnet is expected to be released around the end of Q4 2024.</p>,
  },
  {
    key: '13',
    label: 'My score is not increasing',
    children: <p>Ensure your ip is the same as the dashboard, for any further issues you may open a ticket in the discord channel.</p>,
  },
  {
    key: '14',
    label: 'Is it too late to run my node?',
    children: <p>Only the top 108 placed read-only nodes will receive a reward.</p>,
  },
  {
    key: '15',
    label: 'I want to add validators',
    children: 
    <>
      <p>To add additional validators you need to execute following commands:</p>
      <Space direction='vertical' style={{margin: '12px 0'}}>
        <Text code copyable>docker run -it --rm -v $(pwd)/files/validator_keys:/app/validator_keys repo.mind-dev.com/sanchain/staking-deposit existing-mnemonic --validator_start_index=1 --num_validators=3  --folder=/app --devnet_chain_setting='&#123;"network_name":"sanchain-pos-dev","genesis_fork_version":"0x20000089","genesis_validator_root": "0xe770542f848ac24b8d9b38da6218cce20dc75f03f253d75c0f449fce2a2f9a4b"&#125;' --eth1_withdrawal_address="0x941bCb9Fda08f085ebCf36E4e11e5cf245Db00C6"</Text>
        <Text code copyable>docker run -it --rm -v $(pwd)/files:/files  gcr.io/prysmaticlabs/prysm/validator:stable accounts import --accept-terms-of-use --keys-dir=/files/validator_keys  --wallet-dir=/files/wallet</Text>
      </Space>
      <p>After that new files/validator_keys/deposit_data.json should be created and you need to send that new file to dashboard. For first command you probably want to use same mnemonic as for your first validator.</p>
    </>,
  },
];

export function FaqBlock() {

  const onChange = (key) => {
    // console.log(key);
  };

  return (
    <Wr direction="vertical">
      <Title>Frequently Asked Questions</Title>
      <CollapseWr items={items} defaultActiveKey={['1']} onChange={onChange} />
    </Wr>
  )
}