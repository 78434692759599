import {useEffect, useMemo} from "react";
import {BrowserProvider} from 'ethers';
import styled from 'styled-components';
import {FORM_URL} from "./constants";
import StoredAuth from './localStorage';
import {showNotification} from "./showNotification";
import {ButtonAction} from '../components/ButtonAction/index';

const Wr = styled.div`
  background: ${props => props.$isDisabled ? '#dbdbdb' : '-webkit-linear-gradient(left, #192e4c, #182944)'};
  background-size: 200% 200%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  border-width: 1px;
  padding: 13px 28px;
  cursor: ${props => props.$isDisabled ? 'default' : 'pointer'};
  &:hover {
    background: ${props => props.$isDisabled ? '#dbdbdb' : '-webkit-linear-gradient(left, #e5771b, #e5771b)'};
  }
`;

const Title = styled.div`
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
`;

// const MobAddress = styled.span`
//   background-color: #e5771b;
//   color: black;
//   padding: 5px;
//   border-radius: 5px;
//   border: none;
// `;


export function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

async function connect(setUserAddress) {
  if (!window.ethereum) {
    alert("Get MetaMask!");
    return;
  }
  try {
    const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
    params: [{ eth_accounts: {} }],
    });
    setUserAddress(accounts[0] || null);
  } catch (e) {
    console.log(e.message);
    if (e.code === -32002) {
      showNotification('error', {
        message: `Please, check your MetaMask app`,
        description: e.message
      }, 0);
    }
    else if (e.code === 4001) {
      showNotification('error', {
        message: `Please, check your MetaMask app`,
        description: e.message
      }, 0);
      setUserAddress(null);
    }
  }
}

export const getMessageToSign = async () => {
  console.log('first GET message to SIGN')
  const res = await fetch(FORM_URL + '/api/v1/auth', {
    method: "GET"
  });
  const json = await res.json();
  return {messageToSign: json.code}
};

export const getAuthToken = async (signedMessage, originalMessage) => {
  const headers = {};
  console.log('first CONNECT for TOKEN');
  headers['Content-Type'] = 'application/json';
  const res = await fetch(FORM_URL + '/api/v1/auth', {
    method: "POST",
    headers,
    body: JSON.stringify({signedMessage, originalMessage}),
  });
  const json = await res.json();
  return {data: json}
};

export const personalSign = async (signText) => {
  const Provider = new BrowserProvider(window.ethereum);
  const signedMessage = await (await Provider.getSigner()).signMessage(signText);
  return {
    signedMessage
  }
};

export async function checkIfWalletIsConnected() {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    if (accounts.length > 0) {
      const account = accounts[0];
      if (StoredAuth.get()?.accessToken !== null && StoredAuth.get()?.address !== null) {
        if (StoredAuth.get()?.address?.toLowerCase() !== account) {
          return null;
        } else {
          return account;
        }
      } else {
        return null;

      }
    } else {
      return null;
    }
  }
}

const Connect = ({isSignPopupShown, isConnected, setUserAddress, text}) => useMemo(() => {

  if (!window.ethereum && isMobileDevice()) {
    const dappUrl = "https://nodes.sanr.network"; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    return (
      <a href={metamaskAppDeepLink}>
        <Wr onClick={() => connect(setUserAddress)}>
          <Title>{text}</Title>
        </Wr>
      </a>
    );
  }

  return (
    <ButtonAction disabled={isSignPopupShown} onClickPress={() => connect(setUserAddress)} text={(isSignPopupShown) ? 'Sign MM message' : text}/>
  );
}, [isSignPopupShown, setUserAddress, text]);

export function MetaMaskAuth({userAddress, setUserAddress, isSignPopupShown, isConnected, text}) {

  useEffect(() => {
    (async () => {
      try {
        const connectUser = await checkIfWalletIsConnected();
        setUserAddress(connectUser);
      } catch (e) {
        console.log('ERROR first EFFECT on PAGE', e);
      }
    })();
  }, [setUserAddress]);

  useEffect(() => {
    setUserAddress(userAddress);
  }, [userAddress, setUserAddress]);

  return <Connect isSignPopupShown={isSignPopupShown} isConnected={isConnected} setUserAddress={setUserAddress} text={text}/>;
}
// function Address({userAddress}) {
//   return (
//     <MobAddress>{userAddress.substring(0, 5)}…{userAddress.substring(userAddress.length - 4)}</MobAddress>
//   );
// }
