import {InboxOutlined} from '@ant-design/icons';
import {Upload} from 'antd';
import {showNotification} from '../../utils/showNotification';
// import UseUploadFile from '../../utils/useUploadFile';
import {DEPOSIT_URL} from '../../utils/constants';


const {Dragger} = Upload;

export function ButtonUpload({signedToken}) {

  let errorMessage = '';

  const headers = {};
  headers.Authorization = `Bearer ${signedToken}`;


  const uploadImage = async options => {
    const { onSuccess, onError, file } = options;
    const depositData = new FormData();
    depositData.append('depositData', file);

    const scanDescription =
      (<>
        <span>
          <a href="https://etherscan.io/address/0xbFE5D25956F6D7CF212a24B628c7871A34c4465f#tokentxns" target="_blank" rel="noreferrer"> Check the Etherscan </a>
        </span>
      </>);

    try {
      const res = await fetch(DEPOSIT_URL, {
        method: "POST",
        headers: headers,
        body: depositData,
      }).then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status !== 200) {
          const errorResponse = await response.json();
          console.log(errorResponse);
          if (response.status === 400) {
            errorResponse.message === 'DEPOSIT_IN_MAINNET_NOT_FOUND' && 
            showNotification('error', {
              message: `Stake not found, please, check your staking transaction to the bridge address (on ETH Mainnet) 0xbFE5D25956F6D7CF212a24B628c7871A34c4465f`,
              description: scanDescription
            }, 0);
          } 
          errorMessage = `Message: ${errorResponse.message}, timestamp: ${errorResponse.timestamp}`;
          return;
        }
        console.log(await response.json());
      });
      res ? onSuccess('Ok') : onError('Something went wrong on the server');;
      console.log("server res: ", res);
    } catch (err) {
      console.log("Error: ", err);
      onError({err});
      // throw new Error('Something went wrong');
    }
  };

  const props = {
    name: 'depositData',
    accept: 'application/json',
    headers: headers,
    multiple: false,
    customRequest: uploadImage,
    beforeUpload: (file) => {
      const isPNG = file.type === 'application/json';
      if (!isPNG) {
        showNotification('error', {message: `${file.name} is not a JSON file`, description: 'Please choose the correct file type'});
      }
      return isPNG;
    },
    onChange(info) {
      const {status} = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        showNotification('success', {message: `${info.file.name} file uploaded successfully.`});
      } else if (status === 'error') {
        showNotification('error', {message: `${info.file.name} file upload failed. ${errorMessage}`}, 30);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload your deposit file</p>
      <p className="ant-upload-hint">
        You can send only json files.
      </p>
    </Dragger>
  )
};