import {styled} from 'styled-components';

const colors = {
  primary: {
    bg: '#182944',
    text: '#FFF',
    border: '#dbdbdb',
    bgHover: '#e5771b',
    textHover: '#FFF',
    borderHover: '#dbdbdb',
    bgDisabled: '#dbdbdb',
    textDisabled: '#FFF',
    borderDisabled: 'transparent',
  },
  secondary: {
    bg: '#FFF',
    text: '#182944',
    border: '#182944',
    bgHover: '#FFF',
    textHover: '#6E778D',
    borderHover: '#7A859E',
    bgDisabled: '#dbdbdb',
    textDisabled: '#6E778D',
    borderDisabled: 'transparent',
  }
};

const Title = styled.div`
  color: ${props => props.$isDisabled ? colors[props.$colorType].textDisabled : colors[props.$colorType].text};
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  line-height: 1em;
  font-weight: 600;
  text-align: center;
`;

const Wr = styled.div`
  background: ${props => props.$isDisabled ? colors[props.$colorType].bgDisabled : colors[props.$colorType].bg};
  background-size: 200% 200%;
  border: 1px solid ${props => props.$isDisabled ? colors[props.$colorType].borderDisabled : colors[props.$colorType].border};
  border-radius: 4px;
  padding: 10px 20px;
  cursor: ${props => props.$isDisabled ? 'default' : 'pointer'};
  &:hover {
    background: ${props => props.$isDisabled ? colors[props.$colorType].bgDisabled : colors[props.$colorType].bgHover};
    border: 1px solid ${props => colors[props.$colorType].borderHover};
    ${Title} {
      color: ${props => props.$isDisabled ? colors[props.$colorType].textDisabled : colors[props.$colorType].textHover}
    }
  }
`;

export const ButtonAction = ({disabled=false, colorType="primary", onClickPress=false, isPopupOpen, setIsPopupOpen, text, setIsFileUpload=false, setPopupClose=false, upload}) => {

  return (
    <Wr $isDisabled={disabled} $colorType={colorType} onClick={() => {
      if (setIsFileUpload) {
        upload ? setIsFileUpload(true) : setIsFileUpload(false);
      }
      if (setPopupClose) {
        setPopupClose(false);
      };
      if (setIsPopupOpen) {
        setIsPopupOpen(!isPopupOpen);
      }
      if (onClickPress) {
        onClickPress();
      }
      }}>
      <Title $isDisabled={disabled} $colorType={colorType}>{text}</Title>
    </Wr>
  )
}
