import {Card, Typography, Grid} from 'antd';
import {styled} from 'styled-components';
import StoredAuth from '../../../utils/localStorage';

const {Title, Paragraph} = Typography;
const {useBreakpoint} = Grid;


const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  margin-top: 32px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const H1Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;

const Text = styled(Paragraph)`
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  @media only screen and (max-width: 425px){
      font-size: 12px;
    }
`;

const FormWr = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0 20px;
  @media only screen and (max-width: 767px){
    padding: 0;
  }
`;


export function InfoContent() {
  const userData = {...StoredAuth.get()};
  if (userData.validatorsCount === null) {
    userData.validatorsCount = 1;  //default value of validators in form
  }

  const {sm} = useBreakpoint();

  return (
    <Wr>
      <FormWr>
        <H1Title>Sanr Network Incentivized Testnet Details</H1Title>
        <Title level={!sm ? 3 :2}>About</Title>
        <Text>Sanr Network is a market intelligence layer on Ethereum, an interactive environment for traders, portfolio managers, analysts, and data providers to build reputation, monetize skills and intellectual property.</Text>
        <Text>The current network is operating in test and development mode until the mainnet release in Q2 2024.</Text>
        <Text>Prior to the mainnet release, anyone interested can support our testnet by running a node with a validator or in read-only mode.</Text>
        <Title level={!sm ? 3 :2}>Participation limits</Title>
        <Text>During the current stage of network testing, the limit for nodes with validators is 108. The number of nodes with validators can be tracked on the dashboard.</Text>
        <Text>There is no limit to the number of read-only nodes, but only the 108 nodes with the lowest latency to different geographical regions and fastest block updates receive rewards on a daily basis.</Text>
        <Title level={!sm ? 3 :2}>Phases</Title>
        <Text>The network testing will last for approximately 6 months prior to the release of the mainnet in Q2 2024.</Text>
        <Text>There are a total of two main phases planned for the development of the testnet.</Text>
        <Title level={!sm ? 5 :4}>Phase EVM Proof of Stake (Q3 2023 - Q1 2024)</Title>
        <Text>This phase is aimed at creating the infrastructure to support and develop the decentralized network.</Text>
        <Title level={!sm ? 5 :4}>Phase EVM to zkEVM Migration (Q2 2024)</Title>
        <Text>The main aim is to upgrade the network to zkEVM using the zkStack framework.</Text>
        <Title level={!sm ? 3 :2}>Reward</Title>
        <Text>Node holders will be rewarded in points, which will be taken into account for the airdrop after the mainnet launch.</Text>
      </FormWr>
    </Wr>
  )
}