import {Card, Typography, Grid, List} from 'antd';
import {styled} from 'styled-components';
import StoredAuth from '../../../utils/localStorage';
import {RightCircleOutlined} from '@ant-design/icons';
import {MetaMaskAuth} from '../../../utils/MetaMaskAuth';
import {ButtonAction} from '../../ButtonAction'; 

const {Title, Paragraph} = Typography;
const {useBreakpoint} = Grid;


const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  margin-top: 32px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const H1Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;
// const Subtitle = styled.div`
//   margin: 32px 0 24px;
//   color: #6E778D;
//   font-family: "Poppins", sans-serif;
//   font-size: 18px;
//   font-weight: bold;
//   text-align: center;
//   @media only screen and (max-width: 767px){
//       font-size: 14px;
//     }
//     @media only screen and (max-width: 425px){
//       font-size: 12px;
//     }
// `;

const Text = styled(Paragraph)`
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  @media only screen and (max-width: 425px){
      font-size: 12px;
    }
`;

const FormWr = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0 20px;
  @media only screen and (max-width: 767px){
    padding: 0;
  }
`;

const ButtonWr = styled.div`
  margin: 24px auto;
  max-width: 200px;;
`;

export function ReadOnlyContent({signedToken, userAddress, setUserAddress, isSignPopupShown, isConnected, text, isPopupOpen, setIsPopupOpen, setIsReadOnlyPopupOpen, setIsFileUpload}) {
  const userData = {...StoredAuth.get()};
  if (userData.validatorsCount === null) {
    userData.validatorsCount = 1;  //default value of validators in form
  }

  const {sm} = useBreakpoint();

  const listData = [
    {text: 'Follow the instructions to install the node in read-only mode:  ', link: 'https://github.com/santiment/sanr-pos-network#running-sanr-network-node-in-readonly-mode', linkTitle: 'github'},
    {text: 'If you have any questions or issues with setting up the node, check if there is an answer to your question in the troubleshooting', link: 'https://github.com/santiment/sanr-pos-network#troubleshooting', linkTitle: 'section', moreText: '. Otherwise, create a support ticket in our ', link2: 'https://discord.gg/SFKgvN3XKF', linkTitle2: 'Discord', moreText2: '.'},
    {text: 'Register your node through the form on the dashboard.'},
    {text: 'Obtain the role in ', link: 'https://discord.gg/SFKgvN3XKF', linkTitle: 'Discord', moreText: ' for node holders. To do this, go to our ', link2: 'https://discord.gg/SFKgvN3XKF', linkTitle2: 'Discord', moreText2: ' and tag @kimochiman in @sanr-network-testnet channel.'}
  ];

  return (
    <Wr>
      <FormWr>
        <H1Title>Start run read-only node</H1Title>
        <Title level={!sm ? 3 :2}>About</Title>
        <Text>Node in read only mode can verify the integrity of the data and view transaction history but cannot actively contribute to the blockchain's consensus mechanism.</Text>
        <Title level={!sm ? 3 :2}>Reward</Title>
        <Text>Reward is accrued daily in the form of 1 point.</Text>
        <Text>In order for your node to receive a reward, it needs to be in the top 108 based on lowest latency to different geographical regions and fastest block updates. The rating is updated daily. Amount of points as a reward could be changed in the future.</Text>
        <Title level={!sm ? 3 :2}>Setup</Title>
        <List
        bordered
        dataSource={listData}
        renderItem={(item) => (
        <List.Item>
          <Typography.Text><RightCircleOutlined /></Typography.Text> {item?.text} <a href={item?.link} target="_blank" rel="noreferrer">{item?.linkTitle}</a>{item?.moreText} <a href={item?.link2} target="_blank" rel="noreferrer">{item?.linkTitle2}</a>{item?.moreText2}
        </List.Item>
      )} />
      <ButtonWr>
        {(!userAddress || !signedToken)
          ? <MetaMaskAuth key={2} userAddress={userAddress} setUserAddress={setUserAddress} isSignPopupShown={isSignPopupShown} isConnected={isConnected} text={text}/>
          : <ButtonAction isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} text={text} setPopupClose={setIsReadOnlyPopupOpen} setIsFileUpload={setIsFileUpload}/>
        }
      </ButtonWr>
      </FormWr>
    </Wr>
  )
}