import {Card} from 'antd';
import {styled} from 'styled-components';

const Wr = styled(Card)`
  width: 90%;
  margin: 32px 0;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
     width: 100%;
  }
`;

const Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
    margin: 24px 0 18px;
    font-size: 24px;
  }
  @media only screen and (max-width: 425px){
      font-size: 20px;
  }
`;

export function SignPopup() {
  return (
    <Wr>
      <Title>Please sign the message in the Metamask window</Title>
    </Wr>
  )
}
