const AUTHTOKEN = 'r-auth-token-09/27/2023';
let localStorage = window.localStorage;

const defaultValue = {
  address: null,
  nodeIP: null,
  email: null,
  discordUsername: null,
  validatorsCount: 1,
  stakedSANs: null,
  depositDataPath: null,
  accessToken: null,
};

export default class StoredAuth {
  static get() {
    return JSON.parse(localStorage.getItem(AUTHTOKEN)) || defaultValue;
  }

  static set(value) {
    localStorage.setItem(AUTHTOKEN, JSON.stringify(value === null ? defaultValue : value));
  }
}