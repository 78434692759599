import {HOLDERS_URL} from './constants';


export const getNodeHolders = async () => {
  // const headers = {};
  // headers['Content-Type'] = 'application/json';
  const res = await fetch(HOLDERS_URL, {
    method: "GET",
    // headers,
    // body: JSON.stringify({}),
  });
  const json = await res.json();
  return {data: json}
};