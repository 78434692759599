import {HOLDER_URL} from './constants';


export const getNodeHolder = async (signedToken) => {
  const headers = {};
  headers['Content-Type'] = 'application/json';
  headers.Authorization = `Bearer ${signedToken}`;

  const res = await fetch(HOLDER_URL, {
    method: "GET",
    headers,
    // body: JSON.stringify({}),
  });
  const json = await res.json();
  return {data: json}
};